import formatISO from 'date-fns/formatISO'
import parseISO from 'date-fns/parseISO'
import React, { useState } from 'react'
import styled from 'styled-components'

import { CalendarComponent } from 'web/components/Calendar'
import { Header } from 'web/pages/OrderRequest/styles'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { StyledTextArea } from 'web/components/form/TextAreaField'
import { useOrderContext } from 'web/contexts/OrderContext'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'

export const CompletionDate = () => {
  const { bakery, order } = useOrderContext()
  const { loading, updateOrder } = useUpdateOrder({ order })

  const [completionDate, setCompletionDate] = useState(
    order.completionDate ? parseISO(order.completionDate) : null,
  )

  const onNext = ({ navigateToNextScreen }) => {
    const formattedDate = formatISO(completionDate, { representation: 'date' })

    updateOrder(
      {
        input: { completionDate: formattedDate },
      },
      navigateToNextScreen,
    )
  }

  return (
    <OrderRequestLayout nextDisabled={!completionDate || loading} onNext={onNext}>
      <StyledHeader>When do you need this order?</StyledHeader>
      <CalendarComponent value={completionDate} onChange={setCompletionDate} bakery={bakery} />
    </OrderRequestLayout>
  )
}

export const StyledInput = styled(StyledTextArea)({
  flex: 1,
})

export const StyledHeader = styled(Header)({})
