import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Colors } from 'shared/styles/Colors'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Header, HelpText } from 'web/pages/OrderRequest/styles'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { StyledTextArea } from 'web/components/form'
import { useOrderBuild } from 'web/hooks/useOrderBuild'
import { useOrderContext } from 'web/contexts/OrderContext'
import { useThemeContext } from 'web/contexts/ThemeContext'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'

export const Questionnaire = ({ bakerySlug }) => {
  const { order } = useOrderContext()
  const { backgroundColor } = useThemeContext()

  const { loading, updateOrder } = useUpdateOrder({ order })
  const { customQuestions } = useOrderBuild({ order })

  const [answer, setAnswer] = useState('')
  const [questionIndex, setQuestionIndex] = useState(0)

  const qcIds = useMemo(() => {
    const categories = customQuestions[questionIndex]?.categories || []

    return [...new Set(categories.map((c) => c.id))]
  }, [customQuestions, questionIndex])

  const qoIds = useMemo(() => {
    const offerings = customQuestions[questionIndex]?.offerings || []

    return [...new Set(offerings.map((c) => c.id))]
  }, [customQuestions, questionIndex])

  const helpTextItems = useMemo(() => {
    return [
      ...new Set(
        order?.orderItems.filter((oi) => {
          return qcIds.includes(oi.categoryId) || qoIds.includes(oi.offeringId)
        }),
      ),
    ]
      .map((oi) => oi.offering.name)
      .join(', ')
  }, [qcIds, qoIds, order?.orderItems])

  const { question, required, choices, multiSelect, id } =
    (customQuestions.length && customQuestions[questionIndex]) || {}

  useEffect(() => {
    const previousAnswer = order.customQuestions?.find((q) => {
      if (q.id && id) return q.id === id
      return q.question === question
    })
    if (previousAnswer) {
      setAnswer(previousAnswer.answer || previousAnswer.answers)
    }
  }, [question, choices, order.customQuestions, id])

  const onNext = ({ navigateToNextScreen }) => {
    const _question = helpTextItems.length
      ? `${question} (item(s) specific: ${helpTextItems})`
      : question
    const params = { input: { customQuestion: { question: _question, id } } }
    if (Array.isArray(answer)) params.input.customQuestion.answers = answer
    else params.input.customQuestion.answer = answer

    const moreQuestions = questionIndex + 1 < customQuestions.length

    if (!moreQuestions) params.afterUpdate = navigateToNextScreen
    if (answer) updateOrder(params)
    else if (!moreQuestions) navigateToNextScreen()

    if (moreQuestions) {
      setQuestionIndex(questionIndex + 1)
      setAnswer('')
    }
  }

  const onMultiSelectChoiceClick = (selected, choice) => {
    if (selected) {
      setAnswer(answer.filter((a) => a !== choice.toString()))
    } else {
      setAnswer([...answer, choice.toString()])
    }
  }

  const onChoiceClick = (selected, choice) => {
    if (selected) {
      setAnswer('')
    } else {
      setAnswer(choice)
    }
  }

  return (
    <OrderRequestLayout
      nextDisabled={(required && !answer.length) || loading}
      onBack={questionIndex === 0 ? null : () => setQuestionIndex(questionIndex - 1)}
      onNext={onNext}
      bakerySlug={bakerySlug}
      nextButtonText={required || answer ? 'Next' : 'Skip'}
    >
      <CustomHeader>
        {question} {choices && multiSelect && '(select all that apply)'}
      </CustomHeader>
      <CustomHelpText>{helpTextItems.length ? `item(s): ${helpTextItems}` : ''}</CustomHelpText>

      <OffersContainer>
        {choices ? (
          choices.map((choice, index) => {
            const selected = multiSelect
              ? answer.indexOf(choice.toString()) > -1
              : answer === choice.toString()

            return (
              <Offering
                key={index}
                selected={selected}
                selectedColor={backgroundColor}
                onClick={() => {
                  if (multiSelect) {
                    onMultiSelectChoiceClick(selected, choice)
                  } else {
                    onChoiceClick(selected, choice, index)
                  }
                }}
              >
                {selected && <StyledIcon icon={faCheck} />}
                {choice}
              </Offering>
            )
          })
        ) : (
          <StyledTextArea
            autoFocus={required}
            placeholder={required ? '' : '(optional)'}
            value={answer}
            required={required}
            onChange={(evt) => setAnswer(evt.target.value)}
          />
        )}
      </OffersContainer>
    </OrderRequestLayout>
  )
}

const OffersContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
})

const Offering = styled.div(({ selected, selectedColor }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: 10,
  border: `1px solid ${selected ? Colors.offeringSelectedBgColor : Colors.grey25}`,
  backgroundColor: selected
    ? selectedColor && selectedColor !== Colors.defaultBackground
      ? selectedColor
      : Colors.offeringSelectedBgColor
    : Colors.white,
  color: selected ? Colors.offeringSelectedTextColor : Colors.grey100,
  padding: '5px 20px',
  marginLeft: 10,
  marginRight: 10,
  marginBottom: 20,
  cursor: 'pointer',
}))

const StyledIcon = styled(FontAwesomeIcon)({
  marginRight: 10,
  color: Colors.offeringSelectedTextColor,
})

const CustomHeader = styled(Header)({
  marginBottom: 6,
})

const CustomHelpText = styled(HelpText)({
  fontSize: 18,
  textAlign: 'center',
  marginBottom: 12,
})
