import React, { useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'

import { BakeryContext } from 'web/contexts/BakeryContext'
import { BakeryPageLayout } from 'web/components/BakeryPageLayout'
import { Configuration } from 'shared/services/Configuration'
import { FetchError } from 'shared/components/FetchError'
import { Header } from 'web/pages/OrderRequest/styles'
import { OrderReviewDocument } from 'shared/graphql/queries/Order'
import { ReviewFormComponent } from './ReviewForm'
import { ReviewItem } from '../Reviews'
import { Spinner } from 'shared/components/Spinner'
import { useBakesyQuery } from 'shared/hooks/useBakesyQuery'
import { useEffectOnce } from 'shared/hooks/useEffectOnce'

const ogTitle = 'How was your experience? 😊'
const description = 'Please leave a review'

export const ReviewForm = () => {
  const { orderNumber } = useParams()
  const navigate = useNavigate()

  const [showNotice, setShowNotice] = useState(false)

  const {
    data: order,
    loading,
    refetch,
    error,
  } = useBakesyQuery(OrderReviewDocument, {
    fetchPolicy: 'cache-first',
    initialValue: {},
    transform: (data) => data?.order,
    variables: { orderNumber },
  })

  useEffectOnce(() => {
    if (order.bakery.status !== 'active') navigate('/')
  }, !!order.bakery)

  if (loading) return <Spinner page />
  if (error) return <FetchError page onRetry={refetch} message={error.message} />

  const { review, customer, bakery } = order

  if (!bakery) return

  const name = customer?.name.split(' ')[0]
  const logo = bakery.user.avatar?.smallUrl || `${Configuration.apiUrl}/logo-white-bg-og-v1.png`

  const handleSuccess = () => {
    setShowNotice(true)
    refetch()
  }

  if (review)
    return (
      <BakeryContext.Provider value={{ bakery }}>
        <BakeryPageLayout>
          <Container>
            {showNotice && <Notice>Thank you for leaving a review!</Notice>}
            <ReviewItem reviewId={review.id} />
          </Container>
        </BakeryPageLayout>
      </BakeryContext.Provider>
    )

  if (order.state !== 'complete') return navigate(`/b/${order.bakery.slug}`)

  return (
    <BakeryContext.Provider value={{ bakery }}>
      <BakeryPageLayout>
        <Helmet>
          <title>{ogTitle}</title>
          <meta property="og:title" content={ogTitle} />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={logo} />
        </Helmet>

        <ReviewFormComponent customerName={name} onSuccess={handleSuccess} />
      </BakeryPageLayout>
    </BakeryContext.Provider>
  )
}

const Container = styled.div({
  padding: '5%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100vh',
})

const Notice = styled(Header)({
  fontSize: 40,
  marginBottom: 80,

  '@media (max-width: 767px)': {
    fontSize: '3rem',
    marginBottom: 40,
  },
})
