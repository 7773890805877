import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

import { Alerts } from './Alerts'
import { Colors } from 'shared/styles/Colors'
import { Fonts } from 'shared/styles/Typography'
import { Header } from './Header/Header'
import { PoweredBy } from 'shared/components/PoweredBy'
import { SocialButtons } from 'shared/components/SocialButtons'
import { useBakeryContext } from 'web/contexts/BakeryContext'
import { useEffectOnce } from 'shared/hooks/useEffectOnce'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'
import { useThemeContext } from 'web/contexts/ThemeContext'

export const BakeryPageLayout = ({ children, user }) => {
  const listOuterRef = useRef()
  const listInnerRef = useRef()
  const navigate = useNavigate()

  const {
    backgroundColor: bgColor,
    primaryColor,
    dividerVariant,
    primaryFont,
    secondaryFont,
    facebookHandle,
    instagramHandle,
    tiktokHandle,
  } = useThemeContext()

  const [showScrollIndicator, setShowScrollIndicator] = useState()

  const { bakery } = useBakeryContext()

  const hasScrollIndicator = children?.props ?? false
  /* TODO: may need to get user from fragment instead */
  const hasPremium = (bakery?.user || user)?.subscriptionTier === 'Premium'

  const onScroll = () => {
    if (listOuterRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listOuterRef.current
      if (scrollTop + clientHeight >= 0.95 * scrollHeight) {
        setShowScrollIndicator(false)
      } else {
        setShowScrollIndicator(true)
      }
    }
  }

  /* If bakery is not active, redirect to home page */
  useEffectOnce(() => {
    if (bakery.status !== 'active') navigate('/')
  }, !!bakery)

  useEffect(() => {
    // Hack around Intstagram browser dimensions bug by forcing a fake resize event
    if (navigator.userAgent.match(/instagram/i)) {
      window.dispatchEvent(new CustomEvent('resize'))
    }

    if (listInnerRef.current) {
      setShowScrollIndicator(!allElementsInViewport(listInnerRef.current))
    }
  }, [])

  const allElementsInViewport = (el) => {
    let top = el.offsetTop
    let left = el.offsetLeft
    let width = el.offsetWidth
    let height = el.offsetHeight

    while (el.offsetParent) {
      el = el.offsetParent
      top += el.offsetTop
      left += el.offsetLeft
    }

    return (
      top >= window.pageYOffset &&
      left >= window.pageXOffset &&
      top + height <= window.pageYOffset + window.innerHeight &&
      left + width <= window.pageXOffset + window.innerWidth
    )
  }

  const { isMdUp } = useResponsiveLayout()

  const backgroundRegExp = new RegExp(/category|order-request|products|review/)
  const hasDefaultBackground = backgroundRegExp.test(window.location.pathname)
  const backgroundColor = hasDefaultBackground ? Colors.white : bgColor
  const hasSocial = facebookHandle || instagramHandle || tiktokHandle

  return (
    <Container
      backgroundColor={dividerVariant > 0 && !hasDefaultBackground ? primaryColor : backgroundColor}
      ref={listOuterRef}
      onScroll={onScroll}
      primaryFont={primaryFont}
      secondaryFont={secondaryFont}
    >
      <Alerts />
      <Header bakery={bakery} />
      <div ref={listInnerRef}>{children}</div>

      {hasSocial ? (
        <SocialButtons
          theme={{ facebookHandle, instagramHandle, tiktokHandle, primaryColor }}
          bakeryName={bakery?.name}
        />
      ) : (
        !hasPremium && <PoweredBy customStyle={{ marginBottom: 0 }} />
      )}

      {hasScrollIndicator && !isMdUp && showScrollIndicator && (
        <ScrollIndicatorContainer>
          <ScrollIndicatorCircle>
            <FontAwesomeIcon icon={faChevronDown} color={Colors.grey75} />
          </ScrollIndicatorCircle>
        </ScrollIndicatorContainer>
      )}
    </Container>
  )
}

const Container = styled.div(({ backgroundColor, primaryFont, secondaryFont }) => {
  const primary = primaryFont.includes('Carlito')
    ? Fonts.DefaultPrimary
    : `${primaryFont}, ${Fonts.Fallback}`

  return {
    backgroundColor,
    fontFamily: secondaryFont,
    height: '100%',

    'h1, h2, h3,h4, h5, h6': {
      fontFamily: primary,
    },
  }
})

const ScrollIndicatorContainer = styled.div`
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
`

const ScrollIndicatorCircle = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${Colors.white};
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
`
